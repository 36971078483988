<template>
  <div class="container">
    <!-- 按条件筛选查找数据 -->
    <el-form ref="form" :inline="true" :model="formInline" small="size">
      <el-form-item label="行政区名称：">
        <el-input v-model="formInline.a" placeholder="请输入行政区名称" />
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="queryData()"> 查询 </el-button>
        <el-button type="primary" @click="queryClear()"> 重置 </el-button>
      </el-form-item>
    </el-form>
    <el-table
      ref="multipleTable"
      v-loading="loading"
      :data="orderListData"
      border
      class="width-96"
    >
      <el-table-column type="index" label="序号" width="50" />
      <el-table-column
        prop="professionalTitleCode"
        label="行政区编码"
        align="center"
      />
      <el-table-column
        prop="professionalTitleName"
        label="行政区名称"
        align="center"
      />
      <el-table-column
        label="操作"
        show-overflow-tooltip
        width="200"
        align="center"
      >
        <template slot-scope="scope">
          <el-button
            v-if="$checkPermission('EDITADMINISTRATIVE', $route)"
            type="text"
            size="mini"
            @click="dataOperation(scope.row, 1)"
          >
            编辑
          </el-button>
          <el-button
            v-if="$checkPermission('DELETEADMINISTRATIVE', $route)"
            type="text"
            size="mini"
            @click="dataOperation(scope.row, 1)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页功能模块 -->
    <div class="padding-30">
      <el-pagination
        background
        :page-sizes="[10, 20, 50, 100, 200]"
        :current-page="pageInfo.page"
        :page-size="pageInfo.size"
        layout="->,total, sizes, prev, pager, next, jumper"
        :total="pageInfo.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <el-dialog
      :title="titleType + '行政区信息'"
      :visible.sync="dialogVisible"
      width="35%"
    >
      <el-form
        v-if="dialogVisible"
        ref="form1"
        label-position="right"
        :model="form1"
        :rules="rules"
        label-width="160px"
        @submit.native.prevent
      >
        <h3 style="margin-bottom: 20px; text-align: center">
          确认删除{{}}信息吗？
        </h3>
        <el-form-item label="行政区编码:" prop="professionalTitleCode">
          {{}}
        </el-form-item>
        <el-form-item label="行政区名称:" prop="professionalTitleName">
          <el-input
            v-model="form1.professionalTitleName"
            placeholder="请输入行政区名称"
            style="width: 80%"
            maxlength="20"
            @keyup.enter.native="saveProfessional()"
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveProfessional()">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { nameVerification } from '@/utils/verificationRule.js';
export default {
  name: 'ServiceManageList',
  data() {
    return {
      occupationAllList: [], // 所有职业列表一级全部
      occupationName: '', // 新增时选中的职业名称
      loading: false,
      dialogVisible: false, // 弹框显示与否
      titleType: '', // 是新增还是编辑
      formInline: {
        occupationCode: 'all',
      },
      pageInfo: {
        // 分页功能参数初始化
        page: 1,
        size: 10,
        total: 0,
      },
      form1: {
        occupationCode: '',
        professionalTitleCode: '',
        professionalTitleName: '',
        occupationName: '',
      },
      rules: {
        professionalTitleCode: [
          { required: true, validator: nameVerification, trigger: 'change' },
        ],
        professionalTitleName: [
          { required: true, message: '请输入职业名称', trigger: 'change' },
        ],
      },
      orderListData: [], // 服务列表
    };
  },
  computed: {
    ...mapState('basicDictionary', {
      occupationList: (state) => state.occupationList,
    }),
    // ...mapState('categoryManage', {
    //   categoryList: state => state.categoryList
    // })
  },
  watch: {
    'form1.occupationCode': function (value) {
      this.occupationList.forEach((item) => {
        if (item.occupationCode === value) {
          this.form1.occupationName = item.occupationName;
        }
      });
    },
  },
  mounted() {
    this.getOccupationList();
  },
  methods: {
    ...mapActions('basicDictionary', [
      'professionalPageQuery',
      'professionalEdit',
      'professionalQueryList',
      'occupationQueryList',
    ]),
    // 导入
    importData() {},
    // 分页方法调用
    handleSizeChange(currentPage) {
      this.pageInfo.size = currentPage;
      this.getOccupationList();
    },
    handleCurrentChange(currentPage) {
      this.pageInfo.page = currentPage;
      this.getOccupationList();
    },
    // 重置
    queryClear() {
      this.formInline = {
        // 查询条件
        occupationCode: 'all',
      };
      this.pageInfo = {
        // 分页功能参数初始化
        page: 1,
        size: 10,
        total: 0,
      };
      this.getOccupationList();
    },
    queryData() {
      this.pageInfo.page = 1;
      this.getOccupationList();
    },
    // 分页查询问诊类型列表
    getOccupationList() {
      this.loading = true;
      const params = {
        ...JSON.parse(JSON.stringify(this.formInline)),
        page: {
          done: true,
          pageIndex: this.pageInfo.page,
          pageSize: this.pageInfo.size,
        },
      };
      if (this.formInline.occupationCode === 'all') {
        delete params.occupationCode;
      }
      this.professionalPageQuery(params)
        .then((res) => {
          this.orderListData = res.data.data;
          this.pageInfo.total = Number(res.data.total); // 获取总共有多少条数据
          this.loading = false;
        })
        .catch(() => {});
    },
    // 导出列表
    /**
     * 列表中数据的操作方法
     * 0 - 查看
     * 1 - 编辑
     * 2 - 删除
     */
    dataOperation(item, type) {
      if (type === 1) {
        this.form1 = {
          professionalTitleName: item.professionalTitleName,
          professionalTitleCode: item.professionalTitleCode,
          occupationCode: item.occupationCode,
          occupationName: item.occupationName,
        };
        this.editId = item.id;
        this.titleType = '修改';
      } else {
        this.form1 = {
          professionalTitleName: '',
          professionalTitleCode: '',
          occupationCode: '',
          occupationName: '',
        };
        this.titleType = '新增';
      }
      this.dialogVisible = true;
    },
    // 保存职业
    saveProfessional() {
      this.$refs['form1'].validate((valid) => {
        if (valid) {
          const param = {
            ...JSON.parse(JSON.stringify(this.form1)),
          };
          if (this.titleType === '修改') {
            param.id = this.editId;
          }
          console.log(param);
          this.professionalEdit(param).then((res) => {
            if (res.code === 0) {
              this.$message({
                type: 'success',
                message:
                  this.titleType === '修改'
                    ? '修改行政区信息成功'
                    : '新增行政区信息成功',
              });
              this.dialogVisible = false;
              this.getOccupationList();
            }
            this.$refs['form1'].resetFields();
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  margin-top: 20px;
}

.width-96 {
  width: 96%;
}

.pagination-padding-r {
  padding-right: 60px;
}

.demo-input-suffix {
  padding-top: 30px;
}

body .el-table th.gutter {
  display: table-cell !important;
}

.padding-30 {
  padding-right: 56px;
  margin-top: 10px;
}

.circle {
  width: 25px;
  height: 25px;
  margin-right: 5px;
  border: solid rgb(0, 0, 0) 1px;
  border-radius: 50%;

  /* 宽度和高度需要相等 */
}

.flex {
  display: flex;
}

.orderStatic {
  padding: 10px 0;
}

.labelStyle {
  width: 8%;
  height: 35px;
  margin-right: 1px;
  font-size: 14px;
  text-align: center;
}

.active {
  color: #eee;
  background-color: #409eff;
}
</style>
